/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import './layout.css'

import {Container, Title, InnerContainer, StyledLink, Navigation, Center, Right} from "./style"

const NavCircle = () =>
  <div className="icon baseline">
    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="10" r="10" fill="#333"/></svg>
  </div>


const Layout = ({ children }) => {
  return (
    <Container>
      <Navigation>
        <Title><h1>Carlo Jörges</h1><h2>Product, Design & Interaction</h2></Title>
        <Center>
          <StyledLink to="/" activeClassName="active"><NavCircle />Work</StyledLink>
          <StyledLink to="/about" activeClassName="active"><NavCircle />About</StyledLink>
        </Center>
        <Right>
          <a href="mailto:carlo.joerges@gmail.com">
            <div className="icon baseline">
            <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 17L13 7L23 17" stroke="white" strokeWidth="2"/>
              <rect width="6.32368" height="6.32368" transform="matrix(0.670913 -0.741536 0.670913 0.741536 9.32812 7.92749)" fill="black"/>
              <path d="M3 6L13 15L23 6" stroke="white" strokeWidth="2.5"/>
              <path d="M3 6L13 15L23 6" stroke="black" strokeWidth="2.5"/>
              <path d="M3 3L12.331 11.3979C12.7113 11.7402 13.2887 11.7402 13.669 11.3979L23 3" stroke="white" strokeWidth="2"/>
              <path d="M3 2H23C23.5523 2 24 2.44772 24 3V17C24 17.5523 23.5523 18 23 18H3C2.44772 18 2 17.5523 2 17V3C2 2.44772 2.44772 2 3 2Z" stroke="white" strokeWidth="2"/>
            </svg>
            </div>
 Contact</a>
        </Right>
        
      </Navigation>
      <InnerContainer>
        {children}
      </InnerContainer>
      
      
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
