import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import {TextContent, GridWrap, SectionTitle, Table, CellCaption, CellTile, CellWrap, CellMedia} from "../components/style"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Image from "../components/image"




const Grid = (nodess) => {
  let i = 0;
  let r = [];
  const nodes = [...nodess]
  while (i < nodes.length) {
    r.push(
      <Cell 
          node={nodes[i]} key={i} />)
    i++;
  }
  return <GridWrap>{r}</GridWrap>
}




const Cell = ({node, width = 1}) => 
  <CellWrap width={node.node.frontmatter.ratio}>
    <Link to={node.node.frontmatter.path}>
      <CellTile borderless={node.node.frontmatter.borderless}>
        <CellMedia node={node} />
      </CellTile>
      <CellCaption>
        {node.node.frontmatter.title}
        <small>{node.node.frontmatter.subline || node.node.excerpt}</small>
      </CellCaption>
    </Link>
  </CellWrap>



const IndexPage = ({data}) => 
  <Layout>
    <Seo/>
    {Grid(data.allMarkdownRemark.edges)}
  </Layout>


export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___featured, frontmatter___date], order: [ASC, DESC] }) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date
            borderless
            subline
            featuredVideo {
              publicURL
            }
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
                fixed(width: 400) {
                  src
                  srcWebp
                }
              }
            }
            ratio
          }
          excerpt(pruneLength: 500)
        }
      }
    }
  }
`


export default IndexPage
